@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';
@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.empty-chat-holder {
  width: 100%;
  height: 250px;
  margin-top: 70px;
}

.empty-chat {
  position: relative;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.empty-chat h2 {
  color: #1546dc;
  font-family: 'Abril Fatface';
  padding: 0;
  font-size: 2rem;
  margin: 25px auto 15px;
}

.empty-chat-holder img {
  width: 100%;
  height: 100%;
}

.avatar {
  border-radius: 8px;
  width: 50px;
  height: 50px;
}

.chatSection {
  margin-top: 4px;
}

.header-icons {
  width: 120px;
  float: right;
  margin-right: 10px;
}

.header-icons span {
  line-height: 70px;
  cursor: pointer;
  font-size: 14px;
}

.header-icons .fa {
  cursor: pointer;
  margin: 10px;
}

.msg-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.input-group {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  height: 100%;
  border-radius: 30px;
}

.input-group input {
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.1);
}

.input-group ::placeholder {
  color: #7e79797a !important;
}

.form-control {
  border: none !important;
  border-radius: 20px !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.input-group-text {
  background: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}

.input-group .fa {
  color: #007bff;
  float: right;
}

.bottom-icons {
  float: left;
  margin-top: 17px;
  width: 30% !important;
  margin-left: 22px;
}

.bottom-icons .fa {
  color: #fff;
  padding: 5px;
}

.form-control:focus {
  border-color: none !important;
  box-shadow: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}

.msg-footer {
  border-radius: 10px 10px 10px 10px;
  position: fixed;
  bottom:50px;
  z-index: 10;
  left: 0;
  padding-left: 50px;
  width: 97%;
  height: 60px;
}

#end-of-chat {
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
}

.message-form {
  height: 100%;
}

.chat-box {
  font-family: "Arial","Microsoft YaHei","黑体","宋体",sans-serif;;
  position: relative;
  height: 85%;
  letter-spacing: 0.5px;
  
  padding: 0 !important;
  box-sizing: border-box;
  margin: 0rem 3rem;
  border-color: #828080;
  border-radius: 2rem;
  /* overflow: auto; */
}

@media (max-width: 768px) {
  .chat-box {
    margin: 0rem 1rem 5rem 1rem;
  }
}

.message-input {
  padding: 6px 20px;
  background: rgba(255, 255, 255, 0.1);
}

.empty-chat-sub-title {
  text-align: center;
  color: #fcf9f9;
  font-size: 18px;
}

.welcome-to-chitchat {
  color: #ffffff;
}

.loading-messages-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 150px;
}

.loading-text {
  color: #ffffff;
  font-family: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
  font-size: 25px;
  margin-top: 20px;
}

.chat-bubble-row {
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
}

.sender-name {
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
}

.chat-bubble-container {
  width: 60%; 
}

.chat-bubble {
  border-radius: 8px;
  font-size: 1rem;
  /* padding: 0.7rem; */
  position: relative;
  width: 93%;
}

.message-sender-name {
  padding: 0.7rem 0.7rem 0 0.7rem;
  border-radius: 8px 8px 0 0; /* 上半圆角 */
}

.message-text {
  padding: 0rem 0.7rem 0.7rem 0.7rem;
  white-space: pre-wrap;
  border-radius: 0 0 8px 8px; /* 下半圆角 */
}

.export-icon {
  padding-left: 5px;
  width: 32px; /* 设置图标的宽度 */
  object-fit: contain; /* 确保图片内容按比例缩放以适应分配的宽高，而不变形 */
  height: auto; /* 保持图标的宽高比 */
  transition: transform 0.3s ease; /* 平滑变换效果 */
  cursor: pointer; /* 鼠标悬停时显示手形光标 */
}

.export-icon:hover {
  transform: scale(1.2); /* 鼠标悬停时放大图标 */
}

.message-citation {
  background-color: rgb(191, 190, 190);
  color: rgb(86 86 86);
  padding: 0.7rem;
  border-radius: 0 0 8px 8px; /* 下半圆角 */
}

.is-user {
  background-color: #1546DC;
}

.is-other {
  background-color: #FFF;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.chat-box-bottom {
  margin-top: auto;
}

.svg-icon {
  background-color: transparent;
}

.send-button {
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 1rem;
}

.icons-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.icon {
  width: 25px; 
  height: 25px;
  object-fit: contain; /* 确保图片内容按比例缩放以适应分配的宽高，而不变形 */
  height: auto; /* 保持图标的宽高比 */
  transition: transform 0.3s ease; /* 平滑变换效果 */
  cursor: pointer; /* 鼠标悬停时显示手形光标 */
}

.icon:hover {
  transform: scale(1.2); /* 鼠标悬停时放大图标 */
}

.typing-indicator {
  display: flex;
  align-items: center;
}

.typing-indicator span {
  height: 5px;
  width: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
  opacity: 0;
  animation: typing-indicator-animation 2.4s infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes typing-indicator-animation {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
